import React from "react";

export default function ConsentVaccineRadios({
  formFields,
  options = [],
  batchExists,
  optionValue = "Id",
  optionName = "Name",
  formik,
  onChange = null,
  ...props
}) {
  const label = formFields[props.name]?.Label;
  const hint = formFields[props.name]?.Hint;
  const id = optionValue;
  const name = optionName;

  return (
    <fieldset>
      {label && <legend className="nhsuk-fieldset__legend">{label}</legend>}

      {hint && (
        <div
          className="nhsuk-hint nhsuk-radios__hint ps-0"
          id={`${props.name}Hint`}
        >
          {hint}
        </div>
      )}

      {formik.touched[props.name] && formik.errors[props.name] ? (
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
          {formik.errors[props.name]}
        </span>
      ) : null}

      <div className="nhsuk-form-group">
        <div className="nhsuk-radios">
          {options &&
            options.map((o) => (
              <div key={o[id]} className="nhsuk-radios__item">
                <input
                  type="radio"
                  id={`${props.name}-${o[id]}`}
                  {...props}
                  onChange={onChange ?? formik.handleChange}
                  checked={formik.values[props.name] === o[id]?.toString()}
                  value={o[id]?.toString()}
                  className="nhsuk-radios__input"
                  aria-describedby={`${props.name}${o[id]}`}
                />

                <label
                  htmlFor={`${props.name}-${o[id]}`}
                  className="nhsuk-label nhsuk-radios__label"
                >
                  {o[name]}
                  &nbsp;
                  {formik.values.ConsentVaccineId === o[id]?.toString() && (
                    <>
                      {batchExists ? (
                        <span className="nhsuk-tag nhsuk-tag--green">
                          {" "}
                          Active batches available
                        </span>
                      ) : batchExists == false ? (
                        <span className="nhsuk-tag nhsuk-tag--grey">
                          No active batches
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </label>
              </div>
            ))}
        </div>
      </div>
    </fieldset>
  );
}
