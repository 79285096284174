import React from "react";
import { ReportContext, useReportsContext } from "./ReportsRoot";
import NhsCheckboxes from "../../_shared/components/form/NhsCheckboxes";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import BackButton from "../../_shared/components/BackButton";
import useAnalytics from "../analytics/hooks/useAnalytics";

export const ReportDataSelectionPage: React.FC = () => {
  const { values, onSubmit, dataOptions, guard } = useReportsContext();
  useDocumentTitle("Report data");
  useAnalytics(["service", "reports", "data"]);

  guard(["date", "vaccines", "sites"]);

  return (
    <>
      <BackButton />
      <NhsCheckboxes
        title="Choose data"
        checkboxes={dataOptions.map((dataOption) => ({
          id: dataOption.value,
          label: dataOption.label,
          hint: dataOption.hint,
        }))}
        selectedCheckboxes={values.data}
        setSelectedCheckboxes={(values: ReportContext["values"]["data"]) =>
          onSubmit("data", values, "check")
        }
        errorMessage="Select data for report"
      />
    </>
  );
};

export default ReportDataSelectionPage;
