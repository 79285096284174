import React from "react";
import userService from "./user.service";
import Login from "./pages/Login";
import { Navigate } from "react-router-dom";
import useRegionalAdministratorCheck from "../../_shared/hooks/useRegionalAdministratorCheck";
import useUserOrganisationsCheck from "../../_shared/hooks/useUserOrganisationsCheck";

export default function UserGuard({ element }) {
  const { shouldNavigateToRegional } = useRegionalAdministratorCheck();
  const { shouldChooseOrganisation } = useUserOrganisationsCheck();

  if (shouldNavigateToRegional) {
    return <Navigate to="/regional" />;
  }

  if (shouldChooseOrganisation) return <Navigate to="/select-organisation" />;

  if (userService.getUser()) return element;

  return <Login></Login>;
}
