import { InsetText } from "nhsuk-react-components";
import React from "react";
import { Loading } from "../../_shared/components/Loading";
import ButtonLink from "../../_shared/components/ButtonLink";
import { useReportsContext } from "./ReportsRoot";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";

const ReportsHomePage: React.FC = () => {
  const { sites, isLoading, reset } = useReportsContext();
  const isDisabled = sites.length === 0;
  useDocumentTitle("Report");
  useAnalytics(["service", "reports"]);

  return (
    <div>
      <h1 className="nhsuk-u-margin-bottom-2">Reports</h1>
      <p className="nhsuk-caption-m">Create and download reports</p>
      {isLoading && <Loading />}
      {!isLoading && !isDisabled ? (
        <>
          <InsetText>
            <p>
              Reports may take longer to process if you choose more data or a
              wider date range.
            </p>
          </InsetText>
          <ButtonLink
            onClick={() => {
              reset();
            }}
            to="dates"
            disabled={isDisabled}
          >
            Create report
          </ButtonLink>
        </>
      ) : (
        <InsetText>
          <p>No vaccination data to report on.</p>
        </InsetText>
      )}
    </div>
  );
};

export default ReportsHomePage;
