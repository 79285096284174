import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { ErrorSummary } from "nhsuk-react-components";

interface RadioGroupProps<T> {
  title: string;
  options: T[];
  selectedOption: T | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<T | null>>;
  handleContinue: () => void;
  errorMessage: string;
  getId: (option: T) => number | string;
  getLabel: (option: T) => string;
}

interface FormValues {
  selectedItem: number | string | null;
}

const NhsRadioGroup = <T,>({
  title,
  options,
  selectedOption,
  setSelectedOption,
  handleContinue,
  errorMessage,
  getId,
  getLabel,
}: RadioGroupProps<T>) => {
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [focusField, setFocusField] = useState("");

  const initialValues: FormValues = {
    selectedItem: selectedOption ? getId(selectedOption) : null,
  };

  const validationSchema = Yup.object({
    selectedItem: Yup.mixed().required(errorMessage),
  });

  const scrollToField = (fieldName: string) => {
    const element = document.getElementById(fieldName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  };

  const handleSubmit = (values: FormValues) => {
    const selectedOrg = options.find(
      (option) => getId(option) === values.selectedItem,
    );
    if (selectedOrg) {
      setSelectedOption(selectedOrg);
      handleContinue();
    }
  };

  useEffect(() => {
    if (focusField !== "") {
      scrollToField(focusField);
      setFocusField("");
    }
  }, [focusField]);

  useEffect(() => {
    if (showErrorSummary) {
      setFocusField("formErrors");
    }
  }, [showErrorSummary]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, values, setFieldValue, submitCount }) => (
        <Form>
          {submitCount > 0 && errors.selectedItem && (
            <ErrorSummary id="formErrors">
              <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
              <ErrorSummary.Body>
                <ErrorSummary.List>
                  <ErrorSummary.Item>
                    <button
                      type="button"
                      className={`anchor-style ${hoveredField === "selectedItem" ? "hovered-color" : "error-color"}`}
                      onMouseEnter={() => setHoveredField("selectedItem")}
                      onMouseLeave={() => setHoveredField(null)}
                      onClick={() => scrollToField("selectedItem")}
                    >
                      {errors.selectedItem}
                    </button>
                  </ErrorSummary.Item>
                </ErrorSummary.List>
              </ErrorSummary.Body>
            </ErrorSummary>
          )}
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">{title}</h1>
          </legend>
          {errors.selectedItem && (
            <div id="selectedItem" className="nhsuk-error-message">
              {errors.selectedItem}
            </div>
          )}
          <div
            className={`nhsuk-radios ${errors.selectedItem ? "nhsuk-form-group--error" : ""}`}
          >
            {options.map((option) => (
              <div key={getId(option)} className="nhsuk-radios__item">
                <Field
                  className="nhsuk-radios__input"
                  type="radio"
                  name="selectedItem"
                  value={getId(option)}
                  id={`selectedItem.${getId(option)}`}
                  checked={values.selectedItem === getId(option)}
                  onChange={() => {
                    setFieldValue("selectedItem", getId(option));
                    setSelectedOption(option);
                  }}
                />
                <label
                  className="nhsuk-label nhsuk-radios__label"
                  htmlFor={`selectedItem.${getId(option)}`}
                >
                  {getLabel(option)}
                </label>
              </div>
            ))}
          </div>
          <button
            type="submit"
            className="nhsuk-button nhsuk-u-margin-top-5"
            onClick={() => setShowErrorSummary(true)}
          >
            Continue
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default NhsRadioGroup;
