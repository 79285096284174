import React from "react";
import { BackLink } from "nhsuk-react-components";
import { useNavigate } from "react-router-dom";

/**
 * A button that navigates backwards in the browser history.
 * This component should only be used in the context of a linear form journey.
 * If you need to navigate to a specific page, use a BackButton from nhsuk-react-components instead.
 */
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <BackLink
      href="#"
      onClick={(e) => {
        e.preventDefault();
        navigate(-1);
      }}
    >
      Back
    </BackLink>
  );
};

export default BackButton;
