import React from "react";

import { Button, SummaryList } from "nhsuk-react-components";
import { useReportsContext } from "./ReportsRoot";
import { FormateDate } from "../../_shared/shared.functions";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import { Loading } from "../../_shared/components/Loading";
import BackButton from "../../_shared/components/BackButton";
import useAnalytics from "../analytics/hooks/useAnalytics";

const ReportSummaryPage: React.FC = () => {
  const {
    createReport,
    isLoading,
    sites,
    vaccinePrograms,
    dataOptions,
    values,
    guard,
  } = useReportsContext();
  useDocumentTitle("Report check and confirm");
  useAnalytics(["service", "reports", "check"]);

  guard(["date", "vaccines", "sites", "data"]);

  const getVaccineProgramName = (id: string) => {
    const program = vaccinePrograms.find(
      (program) => id === `${program.VaccineProgramId}`,
    );
    return program ? program.Name : "";
  };

  const getSiteName = (id: string) => {
    const site = sites.find((site) => id === `${site.SiteId}`);
    return site ? site.Name : "";
  };

  const getDataOptionName = (id: string) => {
    const dataOption = dataOptions.find((option) => id === option.value);
    return dataOption ? dataOption.label : "";
  };

  let summaryFields = [];

  if (values.date?.fromDate.getTime() === values.date?.toDate.getTime()) {
    summaryFields.push({
      key: "Date",
      value: values.date ? FormateDate(values.date.fromDate) : "",
      toPage: "../dates",
    });
  } else {
    summaryFields.push(
      {
        key: "From",
        value: values.date ? FormateDate(values.date.fromDate) : "",
        toPage: "../dates",
      },
      {
        key: "To",
        value: values.date ? FormateDate(values.date.toDate) : "",
        toPage: "../dates",
      },
    );
  }

  summaryFields.push(
    {
      key: "Vaccines",
      value: values.vaccines?.map(getVaccineProgramName).join(", "),
      toPage: "../vaccines",
    },
    {
      key: "Sites",
      value: values.sites?.map(getSiteName).join(", "),
      toPage: "../sites",
    },
    {
      key: "Data",
      value: (() => {
        if (values.data.length >= Object.keys(dataOptions).length) {
          return "All data";
        }
        return values.data.map(getDataOptionName).join(", ");
      })(),
      toPage: "../data",
    },
  );

  return (
    <>
      <BackButton />
      <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
        <h1 className="nhsuk-fieldset__heading">Check and confirm</h1>
      </legend>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <SummaryList>
            {summaryFields.map(({ key, value, toPage }) => (
              <SummaryList.Row key={key}>
                <SummaryList.Key>{key}</SummaryList.Key>
                <SummaryList.Value>{value}</SummaryList.Value>
                <SummaryList.Actions>
                  <Link to={toPage}>
                    Change{" "}
                    <span className="nhsuk-u-visually-hidden">
                      {" "}
                      {key.toLowerCase()}
                    </span>
                  </Link>
                </SummaryList.Actions>
              </SummaryList.Row>
            ))}
          </SummaryList>
          <Button onClick={createReport}>Confirm and create report</Button>
        </>
      )}
    </>
  );
};

export default ReportSummaryPage;
