import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { VaccinationSteps, VaccinationTitles } from "../vaccination.enums";
import { PageType } from "../../../_shared/shared.enums";
import NhsDateSummary from "../../../_shared/components/summary/NhsDateSummary";
import NhsOptionSummary from "../../../_shared/components/summary/NhsOptionSummary";
import NhsSummary from "../../../_shared/components/summary/NhsSummary";
import NhsYesNoSummary from "../../../_shared/components/summary/NhsYesNoSummary";
import { GetAge, DateToLongFormat } from "../../../_shared/shared.functions";

interface VaccinationStepConfirmProps {
  patient: any;
  form: any;
  isAddPage: boolean;
  pageType: string;
  changeStep: (step: VaccinationSteps) => void;
  save: () => Promise<void>;
  onDeleteClick: () => Promise<void>;
  options: any;
  formFields: {};
  vaccines: any[];
  saving: boolean;
  stepChanged: boolean;
}

const VaccinationStepConfirm = ({
  patient,
  form,
  isAddPage,
  pageType,
  changeStep,
  save,
  onDeleteClick,
  options,
  formFields,
  vaccines,
  saving,
  stepChanged,
}: VaccinationStepConfirmProps) => {
  const navigate = useNavigate();

  return (
    <>
      <span className="actionwidth">
        {patient && (
          <>
            <h2 className="nhsuk-heading-m nhsuk-u-margin-bottom-2">
              Patient details
            </h2>
            <dl className="nhsuk-summary-list">
              <NhsSummary
                label={"Name"}
                value={patient.FirstName + " " + patient.LastName}
              ></NhsSummary>
              <NhsSummary
                label={"NHS number"}
                value={patient.NhsNumber}
              ></NhsSummary>
              <NhsSummary
                label={"Date of birth"}
                value={`${DateToLongFormat(patient.DateOfBirth)} (aged ${GetAge(patient.DateOfBirth)})`}
              ></NhsSummary>
              <NhsSummary label={"Gender"} value={patient.Gender}></NhsSummary>
              <NhsSummary
                label={"Phone number"}
                value={patient.Telephone}
              ></NhsSummary>
              <NhsSummary
                label={"Address"}
                value={
                  patient.Address +
                  `${patient.Postcode && ", " + patient.Postcode}`
                }
              ></NhsSummary>
            </dl>
          </>
        )}

        {form.values && (
          <>
            <h2 className="nhsuk-heading-m nhsuk-u-margin-bottom-2">
              Choose a vaccine
            </h2>
            <dl className="nhsuk-summary-list mb-1">
              <NhsOptionSummary
                name={"SiteId"}
                label={"Delivery team"}
                options={options?.Sites}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"VaccineProgramId"}
                label={"Vaccine"}
                options={options?.VaccinePrograms}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"VaccineId"}
                label={"Product"}
                optionValue={"VaccineId"}
                options={vaccines}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
            </dl>
            {isAddPage && (
              <p>
                <button
                  className="nhs-link-button"
                  onClick={() => changeStep(VaccinationSteps.Vaccine)}
                >
                  Change the vaccine details
                </button>
              </p>
            )}

            <h2 className="nhsuk-heading-m nhsuk-u-margin-bottom-2">
              {VaccinationTitles.Assessment}
            </h2>
            <dl className="nhsuk-summary-list mb-1">
              <NhsYesNoSummary
                name={"Eligible"}
                label={"Eligible"}
                formFields={formFields}
                formik={form}
              ></NhsYesNoSummary>
              <NhsOptionSummary
                name={"EligibilityTypeId"}
                label={"Eligibility type"}
                options={options?.EligibilityTypes}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"StaffRoleId"}
                options={options?.StaffRoles}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsDateSummary
                name={"ExpectedDueDate"}
                formFields={formFields}
                formik={form}
              ></NhsDateSummary>
              <NhsDateSummary
                name={"AssessmentDate"}
                formFields={formFields}
                formik={form}
                displayWithDay={true}
              ></NhsDateSummary>
              <NhsOptionSummary
                name={"LegalMechanismId"}
                options={options?.LegalMechanisms}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"AssessmentClinicianId"}
                options={options?.Clinicians}
                optionValue={"ClinicianId"}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"AssessmentOutcomeId"}
                options={options?.AssessmentOutcomes}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsSummary
                name={"AssessmentComments"}
                label={"Comments"}
                formFields={formFields}
                formik={form}
              ></NhsSummary>
            </dl>
            {isAddPage && (
              <p>
                <button
                  className="nhs-link-button"
                  onClick={() => changeStep(VaccinationSteps.Assessment)}
                >
                  Change assess the patient page details
                </button>
              </p>
            )}

            <h2 className="nhsuk-heading-m nhsuk-u-margin-bottom-2">
              {VaccinationTitles.Consent}
            </h2>
            <dl className="nhsuk-summary-list nhsuk-u-margin-bottom-2">
              <NhsYesNoSummary
                name={"Consented"}
                label={"Consented"}
                formFields={formFields}
                formik={form}
              ></NhsYesNoSummary>
              <NhsOptionSummary
                name={"ConsentTypeId"}
                options={options?.ConsentTypes}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsSummary
                name={"NameOfPersonConsenting"}
                formFields={formFields}
                formik={form}
              ></NhsSummary>
              <NhsSummary
                name={"RelationshipToPatient"}
                formFields={formFields}
                formik={form}
              ></NhsSummary>
              <NhsOptionSummary
                name={"ConsentClinicianId"}
                options={options?.Clinicians}
                optionValue={"ClinicianId"}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
            </dl>
            {isAddPage && (
              <p>
                <button
                  className="nhs-link-button"
                  onClick={() => changeStep(VaccinationSteps.Consent)}
                >
                  Change record consent page details
                </button>
              </p>
            )}

            <h2 className="nhsuk-heading-m nhsuk-u-margin-bottom-2">
              {VaccinationTitles.Vaccinate}
            </h2>
            <dl className="nhsuk-summary-list nhsuk-u-margin-bottom-2">
              <NhsYesNoSummary
                name={"Vaccinated"}
                label={"Vaccinated"}
                formFields={formFields}
                formik={form}
              ></NhsYesNoSummary>
              <NhsDateSummary
                name={"VaccinationDate"}
                label={"Date"}
                formFields={formFields}
                formik={form}
                displayWithDay={true}
              ></NhsDateSummary>
              <NhsOptionSummary
                name={"CareModelId"}
                label={"Location"}
                options={options?.CareModels}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"VaccineProgramId"}
                label={"Vaccine"}
                options={options?.VaccinePrograms}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"VaccineId"}
                label={"Product"}
                optionValue={"VaccineId"}
                options={vaccines}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsSummary
                name={"BatchNumber"}
                label={"Batch"}
                value={form.values?.BatchNumber}
                formFields={formFields}
                formik={form}
              ></NhsSummary>
              <NhsDateSummary
                name={"BatchExpiryDate"}
                label={"Expiry date"}
                formFields={formFields}
                formik={form}
              ></NhsDateSummary>
              <NhsSummary
                name={"DoseAmount"}
                formFields={formFields}
                formik={form}
              ></NhsSummary>
              <NhsOptionSummary
                name={"VaccinationSiteId"}
                label={"Site"}
                options={options?.VaccinationSites}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsOptionSummary
                name={"VaccinatingClinicianId"}
                options={options?.Clinicians}
                optionValue={"ClinicianId"}
                formFields={formFields}
                formik={form}
              ></NhsOptionSummary>
              <NhsSummary
                name={"VaccinationComments"}
                label={"Comments"}
                formFields={formFields}
                formik={form}
              ></NhsSummary>
            </dl>
            {pageType != PageType.Delete && (
              <p>
                <button
                  className="nhs-link-button"
                  onClick={() => changeStep(VaccinationSteps.Vaccinate)}
                >
                  Change vaccinate page details
                </button>
              </p>
            )}
          </>
        )}

        {pageType == PageType.Delete && (
          <div className="nhsuk-warning-callout">
            <h2 className="nhsuk-warning-callout__label">
              <span role="text">
                <span className="nhsuk-u-visually-hidden">Important: </span>
                The record is ready to be deleted
              </span>
            </h2>
            <p>Are you sure you want to delete this record?</p>
          </div>
        )}

        <div className="nhsuk-form-group">
          <Button
            type="button"
            className="nhsuk-button nhsuk-button--secondary float-end"
            data-module="nhsuk-button"
            onClick={() => navigate(`/patient/${form.values.PatientId}`)}
          >
            Cancel
          </Button>
          {saving ? (
            <Spinner className="float-end"></Spinner>
          ) : (
            <>
              {pageType == PageType.Delete ? (
                <Button
                  type="button"
                  className="nhsuk-button nhsuk-button--warning"
                  data-module="nhsuk-button"
                  onClick={onDeleteClick}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  type="button"
                  className="nhsuk-button"
                  data-module="nhsuk-button"
                  disabled={!isAddPage && !stepChanged}
                  onClick={save}
                >
                  Confirm details and save
                </Button>
              )}
            </>
          )}
        </div>
      </span>
    </>
  );
};

export default VaccinationStepConfirm;
