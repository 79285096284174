import React from "react";
import Profile from "./components/user/pages/Profile";
import PageNotFound from "./components/PageNotFound";
import AuthenticationFailed from "./components/user/pages/AuthenticationFailed";
import AccessDenied from "./components/user/pages/AccessDenied";
import { UserPaths } from "./components/user/user.enums";
import Login from "./components/user/pages/Login";
import UserOrganisation from "./components/user/pages/UserOrganisation";
import PatientSearchNhsNumber from "./components/patient/PatientSearchNhsNumber";
import PatientSearchPds from "./components/patient/PatientSearchPds";
import Patient from "./components/patient/Patient";
import VaccinationAddEdit from "./components/vaccination/VaccinationAddEdit";
import VaccinatorLocationSelect from "./components/vaccinator-location/VaccinatorLocationSelect";
import { VaccinationLocationPaths } from "./components/vaccinator-location/vaccinator-location.enum";
import PatientSearchRecords from "./components/patient/PatientSearchRecords";
import AddVaccine from "./components/site-vaccines/AddVaccine";
import { SiteVaccinesPaths } from "./components/site-vaccines/site-vaccines.enums";
import ConfirmSiteVaccineBatch from "./components/site-vaccines/ConfirmSiteVaccineBatch";
import SiteVaccinesList from "./components/site-vaccines/SiteVaccinesList";
import AddSite from "./components/site-vaccines/AddSite";
import DepleteBatch from "./components/site-vaccines/DepleteBatch";
import SiteVaccineBatches from "./components/site-vaccines/SiteVaccineBatches";
import { PatientPaths } from "./components/patient/patient.enums";
import AddPatient from "./components/patient/AddPatient";
import ConfirmAddPatient from "./components/patient/ConfirmAddPatient";
import PageUnderDev from "./components/PageUnderDev";
import ContactUs from "./components/_layout/ContactUs";
import CookiePolicy from "./components/_layout/cookie/CookiePolicy";
import CookieConfirmation from "./components/_layout/cookie/CookieConfirmation";
import ReportIssue from "./components/_layout/ReportIssue";
import HelpAndGuidance from "./components/_layout/HelpAndGuidance";
import ErrorPage from "./components/Error/ErrorPage";
import ManageUsersPage from "./components/user/pages/ManageUsersPage";
import RegionalUserPage from "./components/user/pages/RegionalUserPage";
import AddEditBatch from "./components/site-vaccines/AddEditBatch";
import SelectOrganisation from "./components/user/pages/SelectOrganisation";
import ManageUsersAddPage from "./components/user/pages/ManageUsersAddPage";
import ManageUsersSummaryPage from "./components/user/pages/ManageUsersSummaryPage";
import { Route } from "react-router-dom";
import ManageUsersRoot from "./components/user/pages/ManageUsersRoot";
import ManageUsersEditPage from "./components/user/pages/ManageUsersEditPage";
import DeactivateUser from "./components/user/pages/DeactivateUser";
import DeactivatedUserList from "./components/user/pages/DeactivatedUserList";
import ActivateUser from "./components/user/pages/ActivateUser";
import ReportsRoot from "./components/reports/ReportsRoot";
import ReportsHomePage from "./components/reports/ReportsHomePage";
import { ReportDateSelectionPage } from "./components/reports/ReportDateSelectionPage";
import ReportVaccineSelectionPage from "./components/reports/ReportVaccineSelectionPage";
import ReportSiteSelectionPage from "./components/reports/ReportSiteSelectionPage";
import ReportDataSelectionPage from "./components/reports/ReportDataSelectionPage";
import ReportSummaryPage from "./components/reports/ReportSummaryPage";
import ReportReadyPage from "./components/reports/ReportReadyPage";

const AppRoutes = [
  {
    index: true,
    userGuard: true,
    element: <PatientSearchNhsNumber />,
  },
  {
    path: "/home",
    userGuard: true,
    element: <PatientSearchNhsNumber />,
  },
  //#region Cookie
  {
    path: "/cookie/policy",
    userGuard: false,
    element: <CookiePolicy />,
  },
  {
    path: "/cookie/confirmation",
    userGuard: false,
    element: <CookieConfirmation />,
  },
  //#region Consent
  {
    path: "/vaccination/add/:id",
    userGuard: true,
    element: <VaccinationAddEdit />,
  },
  {
    path: "/vaccination/edit/:id",
    userGuard: true,
    element: <VaccinationAddEdit />,
  },
  {
    path: "/vaccination/delete/:id",
    userGuard: true,
    element: <VaccinationAddEdit />,
  },
  //#endregion

  //#region Patient
  {
    path: PatientPaths.NhsNumberPatientSearch,
    userGuard: true,
    element: <PatientSearchNhsNumber />,
  },
  {
    path: PatientPaths.PdsPatientSearch,
    userGuard: true,
    element: <PatientSearchPds />,
  },
  {
    path: PatientPaths.PatientRecordSearch,
    userGuard: true,
    element: <PatientSearchRecords />,
  },
  {
    path: PatientPaths.PatientRecord,
    userGuard: true,
    element: <Patient />,
  },
  {
    path: PatientPaths.AddPatient,
    userGuard: true,
    element: <AddPatient />,
  },
  {
    path: PatientPaths.ConfirmAddPatient,
    userGuard: true,
    element: <ConfirmAddPatient />,
  },
  //#endregion
  //#region User
  {
    path: UserPaths.Profile,
    userGuard: false,
    element: <Profile />,
  },
  {
    path: UserPaths.AccessDenied,
    userGuard: false,
    element: <AccessDenied />,
  },
  {
    path: UserPaths.AuthenticationFailed,
    userGuard: false,
    element: <AuthenticationFailed />,
  },
  {
    path: UserPaths.Login,
    userGuard: false,
    element: <Login />,
  },
  {
    path: UserPaths.UserOrganisation,
    userGuard: true,
    element: <UserOrganisation />,
  },
  //#endregion

  //#region Vaccinator Location
  {
    path: VaccinationLocationPaths.VaccinationLocationSelect,
    userGuard: true,
    element: <VaccinatorLocationSelect />,
  },
  //#endregion

  //#region Site Vaccines
  {
    path: SiteVaccinesPaths.AddSite,
    userGuard: true,
    element: <AddSite />,
  },
  {
    path: SiteVaccinesPaths.AddVaccine,
    userGuard: true,
    element: <AddVaccine />,
  },
  {
    path: SiteVaccinesPaths.AddBatch,
    userGuard: true,
    element: <AddEditBatch />,
  },
  {
    path: SiteVaccinesPaths.EditBatch,
    userGuard: true,
    element: <AddEditBatch />,
  },
  {
    path: SiteVaccinesPaths.DepleteBatch,
    userGuard: true,
    element: <DepleteBatch />,
  },
  {
    path: SiteVaccinesPaths.ConfirmSiteVaccineBatch,
    userGuard: true,
    element: <ConfirmSiteVaccineBatch />,
  },
  {
    path: SiteVaccinesPaths.SiteVaccineBatchesList,
    userGuard: true,
    element: <SiteVaccineBatches />,
  },
  {
    path: SiteVaccinesPaths.SiteVaccinesList,
    userGuard: true,
    element: <SiteVaccinesList />,
  },
  //#endregion
  {
    path: "/select-organisation/*",
    userGuard: false,
    element: <SelectOrganisation />,
  },
  {
    path: "/manage-users",
    userGuard: true,
    element: <ManageUsersRoot />,
    children: [
      <Route path="" element={<ManageUsersPage />} />,
      <Route path="add" element={<ManageUsersAddPage />} />,
      <Route path="add/check" element={<ManageUsersSummaryPage />} />,
      <Route path="edit/:id" element={<ManageUsersEditPage />} />,
    ],
  },
  {
    path: "/manage-users/activate/:id",
    userGuard: false,
    element: <ActivateUser />,
  },
  {
    path: "/manage-users/deactivate/:id",
    userGuard: false,
    element: <DeactivateUser />,
  },
  {
    path: "/manage-users/deactivated-users",
    userGuard: false,
    element: <DeactivatedUserList />,
  },
  //#endregion
  {
    path: "/regional/*",
    userGuard: false,
    element: <RegionalUserPage />,
  },
  //{
  //    path: '/records', userGuard: true, element: <PageUnderDev />
  //},
  //{
  //    path: '/help', userGuard: true, element: <PageUnderDev />
  //},
  //{
  //    path: '/dashboard', userGuard: true, element: <PageUnderDev />
  //},
  //{
  //    path: '/workflows', userGuard: true, element: <PageUnderDev />
  //},
  {
    path: "/report-an-issue",
    userGuard: false,
    element: <ReportIssue />,
  },
  {
    path: "/help-and-guidance",
    userGuard: false,
    element: <HelpAndGuidance />,
  },
  {
    path: "/contact-us",
    userGuard: false,
    element: <ContactUs />,
  },
  {
    path: "/sites",
    userGuard: true,
    element: (
      <PageUnderDev analyticsPrimarycategory="sites" pageTitle="Sites" />
    ),
  },
  {
    path: "/vaccine-program-overview",
    userGuard: true,
    element: (
      <PageUnderDev
        analyticsPrimarycategory="vaccine-programme"
        pageTitle="Vaccine Program Overview"
      />
    ),
  },
  {
    path: "/service-unavailable",
    userGuard: false,
    element: <ErrorPage />,
  },
  {
    path: "/reports",
    userGuard: true,
    vaccinatorLocationGuard: false,
    element: <ReportsRoot />,
    children: [
      <Route path="" element={<ReportsHomePage />} />,
      <Route path="dates" element={<ReportDateSelectionPage />} />,
      <Route path="vaccines" element={<ReportVaccineSelectionPage />} />,
      <Route path="sites" element={<ReportSiteSelectionPage />} />,
      <Route path="data" element={<ReportDataSelectionPage />} />,
      <Route path="check" element={<ReportSummaryPage />} />,
      <Route path="ready" element={<ReportReadyPage />} />,
    ],
  },
  //{
  //    path: '/search-tips', userGuard: true, element: <PageUnderDev />
  //},
  {
    path: "*",
    userGuard: false,
    element: <PageNotFound />,
  },
];

export default AppRoutes;
