import React from "react";
import { ButtonLink as NHSButtonLink } from "nhsuk-react-components";
import { LinkProps, useHref, useLinkClickHandler } from "react-router-dom";

interface NHSButtonLinkProps {
  disabled?: boolean;
  secondary?: boolean;
  reverse?: boolean;
}

/**
 * A link that is styled as a button.
 * It uses the NHSUK Button component, but href and click handler props are
 * generated by react-router-dom.
 */
const ButtonLink: React.FC<LinkProps & NHSButtonLinkProps> = (props) => {
  const href = useHref(props.to);
  const routerClickHandler = useLinkClickHandler(props.to, {
    replace: props.replace,
    state: props.state,
    relative: props.relative,
  });
  return (
    <NHSButtonLink
      href={href}
      onClick={(event) => {
        props.onClick && props.onClick(event);
        routerClickHandler(event);
      }}
      disabled={props.disabled}
      secondary={props.secondary}
      reverse={props.reverse}
    >
      {props.children}
    </NHSButtonLink>
  );
};

export default ButtonLink;
