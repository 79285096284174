import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Organisation } from "../../regional/models/organisation.models";
import userService from "../user.service";
import NhsRadioGroup from "../../../_shared/components/form/NhsRadioGroup";
import { Loading } from "../../../_shared/components/Loading";
import { SessionStorageKeys } from "../../../_shared/shared.enums";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";

const SelectOrganisation: React.FC = () => {
  useDocumentTitle("Choose your organisation");
  useAnalytics(["service", "choose-your-organisation"]);
  const navigate = useNavigate();
  const [userOrgs, setUserOrgs] = useState<Organisation[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<Organisation | null>(null);

  useEffect(() => {
    userService.GetUserOrganisations$().then((orgs) => {
      setUserOrgs(orgs);
    });
  }, []);

  const updateClaims = async () => {
    try {
      if (selectedOrg) {
        await userService.updateUserClaim$(selectedOrg.OrganisationId);
      }
    } catch (error) {
      console.error("Failed to send claims");
    }
  };

  const handleContinue = () => {
    if (selectedOrg) {
      sessionStorage.setItem(
        SessionStorageKeys.HasSelectedOrg,
        JSON.stringify(true),
      );
      updateClaims();
      navigate(`/home`);
    }
  };

  return (
    <div className="nhsuk-grid-row">
      <div className="nhsuk-grid-column-two-thirds">
        {userOrgs.length > 0 ? (
          <NhsRadioGroup<Organisation>
            title="Choose your organisation"
            options={userOrgs}
            selectedOption={selectedOrg}
            setSelectedOption={setSelectedOrg}
            handleContinue={handleContinue}
            errorMessage="Select an organisation"
            getId={(org) => org.OrganisationId}
            getLabel={(org) => `${org.Name} (${org.Code})`}
          />
        ) : (
          <Loading message="Loading organisations..." />
        )}
      </div>
    </div>
  );
};

export default SelectOrganisation;
