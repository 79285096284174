import React from "react";
import { Button } from "nhsuk-react-components";
import reportsService from "../../_shared/services/reports/reports.service";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import { useReportsContext } from "./ReportsRoot";
import useAnalytics from "../analytics/hooks/useAnalytics";

const ReportReadyPage: React.FC = () => {
  const { reportBlobUrl } = useReportsContext();
  useDocumentTitle("Report ready");
  useAnalytics(["service", "reports", "ready"]);

  const navigate = useNavigate();
  if (!reportBlobUrl) {
    navigate("..");
    return null;
  }

  const downloadReport = () => {
    reportsService.downloadReport(reportBlobUrl);
    navigate("..");
    return null;
  };

  return (
    <div>
      <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1 className="nhsuk-fieldset__heading">Report is ready</h1>
      </legend>
      <Button onClick={downloadReport}>Download report</Button>
    </div>
  );
};

export default ReportReadyPage;
