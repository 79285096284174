import { useUser } from "../../components/user/UserProvider";
import { SessionStorageKeys } from "../shared.enums";

const useUserOrganisationsCheck = () => {
  const user = useUser();
  var hasSelected = JSON.parse(
    sessionStorage.getItem(SessionStorageKeys.HasSelectedOrg),
  );

  const shouldChooseOrganisation = user?.HasMultipleOrgs && !hasSelected;

  return {
    shouldChooseOrganisation,
  };
};

export default useUserOrganisationsCheck;
