import React from "react";
import { ErrorSummary } from "nhsuk-react-components";
import { FormikErrors } from "formik";

/**
 * Errors from formik could contain nested objects.
 * This function flattens the errors into a single level object for easy rendering.
 */
const flattenErrors = (errors: FormikErrors<any>) => {
  const result: FormikErrors<{ [field: string]: string }> = {};

  const recurse = (obj: FormikErrors<any>, currentPath: string[] = []) => {
    for (const key in obj) {
      const newPath = currentPath.concat(key);
      if (Array.isArray(obj[key])) {
        throw new Error("Arrays are not supported in formik error summary");
      } else if (typeof obj[key] === "object") {
        // This `as` assertion will not be necessary in the latest versions of TypeScript
        recurse(obj[key] as FormikErrors<any>, newPath);
      } else {
        // This `as` assertion will not be necessary in the latest versions of TypeScript
        result[newPath.join(".")] = obj[key] as string;
      }
    }
  };

  recurse(errors);

  return result;
};

const scrollToField = (fieldName: string) => {
  const element = document.getElementById(fieldName);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    element.focus();
  }
};

interface FormikErrorSummaryProps {
  /**
   * Errors from `<Formik>`'s render props.
   * Nothing will be rendered if this is `undefined`, or empty.
   */
  errors: FormikErrors<any> | undefined;
}

/**
 * Renders a NHS.UK error summary component based on formik errors.
 * Usage:
 * ```
 * <Formik>
 *  {({ errors }) => (
 *    <Form>
 *      <FormikErrorSummary errors={errors} />
 *      // ... fields
 *    </Form>
 *  )}
 * </Formik>
 * ```
 *
 */
const FormikErrorSummary: React.FC<FormikErrorSummaryProps> = ({ errors }) => {
  if (!errors) return null;
  const flattenedErrors = flattenErrors(errors);
  if (Object.keys(flattenedErrors).length === 0) return null;

  return (
    <ErrorSummary id="formErrors">
      <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
      <ErrorSummary.Body>
        <ErrorSummary.List>
          {Object.keys(flattenedErrors).map((fieldName, index) => (
            <ErrorSummary.Item
              href={`#${fieldName}`}
              onClick={(event) => {
                event.preventDefault();
                scrollToField(fieldName);
              }}
              key={index}
            >
              {flattenedErrors[fieldName]}
            </ErrorSummary.Item>
          ))}
        </ErrorSummary.List>
      </ErrorSummary.Body>
    </ErrorSummary>
  );
};

export default FormikErrorSummary;
