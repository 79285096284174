import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import {
  AddBatchAnalyticsSubCategories,
  SiteVaccinesAnalyticsPageNames,
  SiteVaccinesPageTitles,
  SiteVaccinesPaths,
} from "./site-vaccines.enums";
import { RemoveTime, scrollToElementId } from "../../_shared/shared.functions";
import { SiteVaccines } from "./site-vaccines.models";
import { BatchDto } from "../batch/batch.models";
import siteVaccinesService from "./site-vaccines.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { object, string, date } from "yup";
import { ErrorSummary } from "nhsuk-react-components";

export default function AddEditBatch() {
  const location = useLocation();
  const navigate = useNavigate();

  const [options, setOptions] = useState(location.state?.[0]);
  const userSites = location.state?.[1];
  const [siteVaccines, setSiteVaccines] = useState(
    location.state?.[2] as SiteVaccines[],
  );
  const [batch, setBatch] = useState(location.state?.[3] as BatchDto);
  const siteVaccineBatchesStateData = location.state?.[4] as BatchDto[];
  const addOrEdit = location.state?.[5];
  const siteVaccine = location.state?.[7] as SiteVaccines;
  const [showErrorSummary, setShowErrorSummary] = useState(false);

  const pageAction = addOrEdit?.IsEditBatch
    ? SiteVaccinesPageTitles.EditBatch
    : SiteVaccinesPageTitles.AddBatch;
  useDocumentTitle(pageAction);

  const subCategory2 = addOrEdit?.IsEditBatch
    ? AddBatchAnalyticsSubCategories.SubCategory2Edit
    : AddBatchAnalyticsSubCategories.SubCategory2Add;
  useAnalytics([
    "service",
    SiteVaccinesAnalyticsPageNames.PrimaryCategory,
    AddBatchAnalyticsSubCategories.SubCategory1,
    subCategory2,
  ]);

  const batchExpiryDate =
    batch && batch?.ExpiryDate?.includes("T")
      ? RemoveTime(batch?.ExpiryDate)
      : batch && !batch?.ExpiryDate?.includes("T")
        ? batch?.ExpiryDate
        : "";

  const formik = useFormik({
    initialValues: {
      VaccineProgramId: batch?.VaccineProgramId.toString(),
      OtherBatchNumber: batch?.BatchNumber?.toUpperCase(),
      ExpiryDate_1: batch ? batchExpiryDate?.split("-")[2] : "",
      ExpiryDate_2: batch ? batchExpiryDate?.split("-")[1] : "",
      ExpiryDate_3: batch ? batchExpiryDate?.split("-")[0] : "",
      ExpiryDate: batch ? batchExpiryDate : "",
      BatchExists: "no",
    },
    validationSchema: object({
      OtherBatchNumber: string()
        .required("Enter the batch number")
        .test("no-whitespace", "Enter the batch number", (value) => {
          if (value.trim()) return true;
          return false;
        })
        .test("alphanumeric", "Incorrect batch number", (value) => {
          return /^[A-Za-z0-9-]*$/.test(value.trim());
        }),
      BatchExists: string().required(
        "There is already a batch with this number and expiry date",
      ),
      ExpiryDate: date()
        .min(new Date(), "Expiry date must be in the future")
        .required("Enter the expiry date"),
    }),

    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const values = formik.values;
    let day = values.ExpiryDate_1?.toString();
    if (day && day.length === 1) day = "0" + day;
    let month = values.ExpiryDate_2?.toString();
    if (month && month.length === 1) month = "0" + month;
    let year = values.ExpiryDate_3;
    const dateString = year + "-" + month + "-" + day;
    const dateIsValid = moment(dateString, "YYYY-MM-DD", true).isValid();
    const dateValue = dateIsValid ? dateString : "";
    formik.setFieldValue("ExpiryDate", dateValue);
  }, [
    formik.values.ExpiryDate_1,
    formik.values.ExpiryDate_2,
    formik.values.ExpiryDate_3,
  ]);

  useEffect(() => {
    if (batch) {
      const checkAddressExists = async () => {
        if (!batch.AddrLn1) {
          let orgDetails = await siteVaccinesService.nhsdOrganisation$(
            batch.Code,
          );

          setBatch((prevState) => ({
            ...prevState,
            AddrLn1: orgDetails?.AddressLine1,
            AddrLn2: orgDetails?.AddressLine2,
            Town: orgDetails?.Town,
            County: orgDetails?.County,
            PostCode: orgDetails?.PostCode,
          }));
        }
      };

      checkAddressExists();
    }
  }, [batch]);

  useEffect(() => {
    checkBatchExists();
  }, [formik.values.OtherBatchNumber, formik.values.ExpiryDate]);

  async function checkBatchExists() {
    formik.setFieldValue("BatchExists", "no");
    if (
      formik.values.OtherBatchNumber &&
      formik.values.OtherBatchNumber.trim() &&
      formik.values.ExpiryDate
    ) {
      const batchExists = await siteVaccinesService.batchExists$({
        ...batch,
        BatchNumber: formik.values.OtherBatchNumber.trim(),
        ExpiryDate: formik.values.ExpiryDate,
      });
      if (batchExists) formik.setFieldValue("BatchExists", "");
    }
  }

  const showBatchNumberError = () => {
    return formik.errors.OtherBatchNumber && formik.touched.OtherBatchNumber;
  };

  const showExpiryDateError = () => {
    return (
      (formik.errors.ExpiryDate || formik.errors.BatchExists) &&
      (formik.touched.ExpiryDate_1 ||
        formik.touched.ExpiryDate_2 ||
        formik.touched.ExpiryDate_3)
    );
  };

  async function handleSubmit() {
    if (!Object.keys(formik.errors)?.length) {
      batch.BatchNumber = formik.values.OtherBatchNumber.trim();
      batch.ExpiryDate = formik.values.ExpiryDate;

      if (addOrEdit?.IsEditBatch) {
        //perform api edit update
        var returnedResult =
          await siteVaccinesService.editSiteVaccineBatch$(batch);

        setSiteVaccines(returnedResult.SitesVaccines);
        let updatedOptions = options;
        updatedOptions.Sites = returnedResult.Sites;
        setOptions((prevState) => ({
          ...prevState,
          Sites: returnedResult.Sites,
        }));
        navigate(SiteVaccinesPaths.SiteVaccineBatchesList, {
          state: [
            updatedOptions,
            returnedResult.Sites,
            returnedResult.SitesVaccines,
            returnedResult.SitesVaccines.find(
              (sv) => sv.SiteVaccineId === siteVaccine.SiteVaccineId,
            ) as SiteVaccines,
          ],
        });
      } else {
        //continue to confirmPage (add)
        navigate(SiteVaccinesPaths.ConfirmSiteVaccineBatch, {
          state: [
            options,
            userSites,
            siteVaccines,
            batch,
            siteVaccineBatchesStateData,
            addOrEdit,
            null,
            siteVaccine,
          ],
        });
      }
    }
  }

  return (
    <>
      {location && location.state ? (
        <>
          {!addOrEdit ? (
            <>
              <div className="nhsuk-back-link">
                <Link
                  className="nhsuk-back-link__link"
                  to={{ pathname: SiteVaccinesPaths.AddVaccine }}
                  state={[options, userSites, siteVaccines, batch]}
                >
                  <svg
                    className="nhsuk-icon nhsuk-icon__chevron-left"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    height="24"
                    width="24"
                  >
                    <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                  </svg>{" "}
                  Back
                </Link>
              </div>
            </>
          ) : (
            <>
              {addOrEdit?.IsAddEditBatchFromSiteBatchesList === true ? (
                <div className="nhsuk-back-link">
                  <Link
                    className="nhsuk-back-link__link"
                    to={{ pathname: SiteVaccinesPaths.SiteVaccineBatchesList }}
                    state={[options, userSites, siteVaccines, siteVaccine]}
                  >
                    <svg
                      className="nhsuk-icon nhsuk-icon__chevron-left"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      height="24"
                      width="24"
                    >
                      <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                    </svg>{" "}
                    Back
                  </Link>
                </div>
              ) : (
                <>
                  {addOrEdit?.IsAddEditBatchFromSiteVaccinesList === true && (
                    <div className="nhsuk-back-link">
                      <Link
                        className="nhsuk-back-link__link"
                        to={{ pathname: SiteVaccinesPaths.SiteVaccinesList }}
                        state={[null, [] as SiteVaccines[]]}
                      >
                        <svg
                          className="nhsuk-icon nhsuk-icon__chevron-left"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="24"
                          width="24"
                        >
                          <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                        </svg>{" "}
                        Back
                      </Link>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="nhsuk-grid-row mt-3">
            <div className="nhsuk-grid-column-two-thirds">
              {showErrorSummary && Object.keys(formik.errors).length ? (
                <ErrorSummary id="formErrors">
                  <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
                  <ErrorSummary.Body>
                    <ErrorSummary.List>
                      {Object.keys(formik.errors).map((fieldName, index) => (
                        <ErrorSummary.Item key={index}>
                          <button
                            className={"anchor-style error-color"}
                            key={index}
                            onClick={() => scrollToElementId(fieldName)}
                          >
                            {formik.errors[fieldName]}
                          </button>
                        </ErrorSummary.Item>
                      ))}
                    </ErrorSummary.List>
                  </ErrorSummary.Body>
                </ErrorSummary>
              ) : (
                <></>
              )}

              <form onSubmit={formik.handleSubmit}>
                <fieldset className="nhsuk-fieldset mb-3">
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                    <h1 className="nhsuk-fieldset__heading">
                      {addOrEdit?.IsEditBatch ? "Edit batch" : "Add batch"}
                    </h1>
                  </legend>
                </fieldset>

                <div
                  className={
                    "nhsuk-form-group " +
                    (showBatchNumberError() ? "nhsuk-form-group--error" : "")
                  }
                >
                  <label className="nhsuk-label" htmlFor="OtherBatchNumber">
                    Batch number
                  </label>

                  {showBatchNumberError() && (
                    <span
                      className="nhsuk-error-message"
                      id="OtherBatchNumber-Error"
                    >
                      {formik.errors.OtherBatchNumber}
                    </span>
                  )}

                  <input
                    className={
                      "nhsuk-input nhsuk-input--width-10 " +
                      (showBatchNumberError() ? "nhsuk-input--error" : "")
                    }
                    id="OtherBatchNumber"
                    name="OtherBatchNumber"
                    value={formik.values.OtherBatchNumber}
                    type="text"
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      const start = target.selectionStart;
                      const end = target.selectionEnd;
                      target.value = target.value.toUpperCase();
                      target.setSelectionRange(start, end);
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                  />
                </div>

                <div
                  className={
                    "nhsuk-form-group " +
                    (showExpiryDateError() ? "nhsuk-form-group--error" : "")
                  }
                >
                  <hr></hr>
                  <fieldset
                    className="nhsuk-fieldset"
                    aria-describedby="ExpiryDate-hint"
                    role="group"
                  >
                    <legend className="nhsuk-fieldset__legend">
                      Expiry date
                    </legend>

                    <div className="nhsuk-hint" id="ExpiryDate-hint">
                      For example, 31 03 {new Date().getFullYear() + 1}
                    </div>

                    {showExpiryDateError() && (
                      <span
                        className="nhsuk-error-message"
                        id="ExpiryDate-Error"
                      >
                        <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
                        {formik.errors.ExpiryDate
                          ? formik.errors.ExpiryDate
                          : formik.errors.BatchExists}
                      </span>
                    )}

                    <div className="nhsuk-date-input" id="ExpiryDate">
                      <div className="nhsuk-date-input__item">
                        <div className="nhsuk-form-group">
                          <label
                            className="nhsuk-label nhsuk-date-input__label"
                            htmlFor="ExpiryDate_1"
                          >
                            Day
                          </label>

                          <input
                            className={
                              "nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " +
                              (showExpiryDateError()
                                ? "nhsuk-input--error"
                                : "")
                            }
                            id="ExpiryDate_1"
                            name="ExpiryDate_1"
                            type="number"
                            inputMode="numeric"
                            value={formik.values.ExpiryDate_1 || ""}
                            placeholder="DD"
                            maxLength={2}
                            pattern="[0-9]*"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className="nhsuk-date-input__item">
                        <div className="nhsuk-form-group">
                          <label
                            className="nhsuk-label nhsuk-date-input__label"
                            htmlFor="ExpiryDate_2"
                          >
                            Month
                          </label>

                          <input
                            className={
                              "nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " +
                              (showExpiryDateError()
                                ? "nhsuk-input--error"
                                : "")
                            }
                            id="ExpiryDate_2"
                            name="ExpiryDate_2"
                            type="number"
                            inputMode="numeric"
                            value={formik.values.ExpiryDate_2 || ""}
                            placeholder="MM"
                            maxLength={2}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className="nhsuk-date-input__item">
                        <div className="nhsuk-form-group">
                          <label
                            className="nhsuk-label nhsuk-date-input__label"
                            htmlFor="ExpiryDate_3"
                          >
                            Year
                          </label>

                          <input
                            className={
                              "nhsuk-input nhsuk-date-input__input nhsuk-input--width-4 " +
                              (showExpiryDateError()
                                ? "nhsuk-input--error"
                                : "")
                            }
                            id="ExpiryDate_3"
                            name="ExpiryDate_3"
                            type="number"
                            inputMode="numeric"
                            value={formik.values.ExpiryDate_3 || ""}
                            placeholder="YYYY"
                            maxLength={4}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="mt-3">
                  <Button
                    type="submit"
                    className="nhsuk-button"
                    onClick={() => setShowErrorSummary(true)}
                  >
                    {addOrEdit && addOrEdit?.IsEditBatch ? (
                      <> Confirm</>
                    ) : (
                      <>Continue</>
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        navigate(SiteVaccinesPaths.SiteVaccineBatchesList)
      )}
    </>
  );
}
