import React, { useState } from "react";
import { Select, TextInput } from "nhsuk-react-components";
import { Field } from "formik";
import "./NhsComponents.scss";

export default function NhsSelect({
  formFields,
  options = [],
  label,
  name,
  ...props
}) {
  const [error, setError] = useState<string>("");

  return (
    <div
      className={`nhsuk-form-group ${error ? "nhsuk-form-group--error" : ""}`}
    >
      {label && (
        <label htmlFor={name} className="nhsuk-label">
          {label}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }) => {
          // Update error message when meta changes
          const errorMessage = meta.touched && meta.error ? meta.error : "";

          // If there's an error, update the local state
          if (errorMessage !== error) {
            setError(errorMessage);
          }

          return (
            <>
              <Select
                {...field}
                {...props}
                id={name}
                value={field.value || ""}
                error={errorMessage}
              >
                <Select.Option value=""></Select.Option>
                {options.map((o, index) => (
                  <Select.Option key={index} value={o.Id}>
                    {o.Name}
                  </Select.Option>
                ))}
              </Select>

              {/* Optionally render a TextInput below for error messages */}
              {meta.touched && meta.error && (
                <TextInput
                  error={meta.error}
                  label="Error"
                  disabled
                  value={meta.error}
                  onChange={() => {}}
                />
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
}
