import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import patientService from "./patient.service";
import PatientDetails from "./PatientDetails";
import NhsBackLink from "../../_shared/components/NHSUK/NhsBackLink";
import {
  GetAge,
  IsoToDateFornat,
  ScrollToTop,
} from "../../_shared/shared.functions";
import { GenderName } from "./patient.functions";
import VaccinationLists from "../vaccination/VaccinationLists";
import { PatientPageTitles, PatientCategory } from "./patient.enums";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import { Loading } from "../../_shared/components/Loading";
import { WarningCallout } from "nhsuk-react-components";

export default function Patient() {
  const location = useLocation();
  const actionPerformedState =
    location && location.state ? location.state[0] : null;
  let primaryCategory1 = "patient";
  let subCategory1 = "";
  if (actionPerformedState) {
    if (actionPerformedState.ActionType === "add") {
      primaryCategory1 = "patient-details";
    } else if (actionPerformedState.ActionType === "edit") {
      subCategory1 = "edit";
    } else if (actionPerformedState.ActionType === "delete") {
      subCategory1 = "delete";
    }
  }
  useAnalytics(["service", primaryCategory1, subCategory1]);
  useDocumentTitle(PatientPageTitles.PatientDetails);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState(null);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);

  useEffect(() => {
    if (id) {
      getPatient();
    }
  }, []);

  const getPatient = async () => {
    setPatient(await patientService.get$(id).finally(() => setLoading(false)));
  };
  const editPatient = async () => {
    patient.PdsPatientDto.PatientId = patient.PatientId;
    patient.PdsPatientDto.Address = patient.PdsPatientDto.Address.trim();
    setPatient(
      await patientService.upsert$(patient.PdsPatientDto).finally(() => {
        setLoading(false);
        ScrollToTop();
        setShowUpdateSuccess(true);
      }),
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <NhsBackLink url={"/patient/search/nhs-number"}></NhsBackLink>
          <h1 className="nhsuk-heading-l">
            {patient.FirstName} {patient.LastName}
          </h1>
          {patient && (
            <>
              {showUpdateSuccess && (
                <>
                  <div className="col-12">
                    <div className="card mb-3 background-success-border">
                      <div className="card-header text-white background-success">
                        Patient details updated
                      </div>
                      <div className="card-body">
                        <p className="card-text">
                          RAVS now reflects {patient.FirstName}{" "}
                          {patient.LastName}'s PDS patient details.
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <PatientDetails patient={patient}></PatientDetails>
              {patient.IsDeceased && (
                <WarningCallout>
                  <WarningCallout.Label>Important</WarningCallout.Label>
                  <p>
                    This patient has died according to the Personal Demographics
                    Service (PDS).
                  </p>
                </WarningCallout>
              )}
              {patient.PdsPatientDto && (
                <>
                  <div className="nhsuk-card">
                    <div className="nhsuk-card__content">
                      <h3>PDS patient details</h3>

                      <dl className="nhsuk-summary-list">
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">Name</dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.FirstName}{" "}
                            {patient.PdsPatientDto.LastName}
                          </dd>
                        </div>

                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">
                            NHS number
                          </dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.NhsNumber}
                          </dd>
                        </div>

                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">
                            Date of birth
                          </dt>
                          <dd className="nhsuk-summary-list__value">
                            <div>
                              {IsoToDateFornat(
                                patient.PdsPatientDto.DateOfBirth,
                              )}{" "}
                            </div>
                            <div>
                              (aged {GetAge(patient.PdsPatientDto.DateOfBirth)})
                            </div>
                          </dd>
                        </div>

                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">Gender</dt>
                          <dd className="nhsuk-summary-list__value">
                            {GenderName(patient.PdsPatientDto.GenderId)}
                          </dd>
                        </div>

                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">
                            Phone number
                          </dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.Telephone}
                          </dd>
                        </div>

                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">Address</dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.Address &&
                              patient.PdsPatientDto.Address + ","}{" "}
                            {patient.PdsPatientDto.Postcode}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <Button
                    type="button"
                    className="nhsuk-button float-end"
                    data-module="nhsuk-button"
                    onClick={(e) => {
                      e.preventDefault();
                      editPatient();
                    }}
                  >
                    Update RAVS with PDS record
                  </Button>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </>
              )}
              <Button
                type="button"
                className="nhsuk-button"
                data-module="nhsuk-button"
                onClick={() =>
                  navigate(`/vaccination/add/${patient.PatientId}`)
                }
              >
                Choose vaccine
              </Button>
              <VaccinationLists
                vaccinations={patient.Vaccinations}
              ></VaccinationLists>
            </>
          )}
        </>
      )}
    </>
  );
}
