import React, { useState } from "react";
import { NavLink } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Patient, PatientSearchType } from "./patient.models";

import { IsoToUkDate } from "../../_shared/shared.functions";
import NhsActionLink from "../../_shared/components/NHSUK/NhsActionLink";
import {
  PatientSearchAnalyticsPrimaryCategory,
  PatientPageTitles,
  PatientSearchRecordAnayticsPageName,
} from "./patient.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import PatientForm, { PatientFormInput } from "./PatientForm";
import FormikErrorSummary from "../reports/ErrorSummary";
import { FormikErrors } from "formik";

export default function PatientSearchRecords() {
  useAnalytics([
    "service",
    PatientSearchAnalyticsPrimaryCategory.PrimaryCategory,
    PatientSearchRecordAnayticsPageName.SubCategory1,
  ]);
  useDocumentTitle(PatientPageTitles.PatientRecordSearch);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchValues, setSearchValues] = useState(null as Patient);
  const [patients, setPatients] = useState([] as Patient[]);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [formikErrors, setFormikErrors] = useState<
    FormikErrors<PatientFormInput>
  >({});

  const recordVaccination = (patient) => {
    navigate(`/patient/${patient.PatientId}`);
  };

  const patientSearchType: PatientSearchType = {
    NhsNumberSearch: false,
    PdsSearch: false,
    RavsSearch: true,
  };

  return (
    <>
      <FormikErrorSummary errors={formikErrors} />
      <fieldset className="nhsuk-fieldset">
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
          <h1 className="nhsuk-fieldset__heading">Find a patient</h1>
        </legend>

        <div className="nhsuk-tabs" data-module="nhsuk-tabs">
          <ul
            className="nhsuk-tabs__list"
            aria-label="Patient search by local records tab"
          >
            <li className="nhsuk-tabs__list-item">
              <NavLink tag={Link} to="/patient/search/nhs-number">
                By NHS number
              </NavLink>
            </li>
            <li className="nhsuk-tabs__list-item">
              <NavLink tag={Link} to="/patient/search/pds">
                By demographics
              </NavLink>
            </li>
            <li className="nhsuk-tabs__list-item nhsuk-tabs__list-item--selected">
              <NavLink href="#" active>
                By local records
              </NavLink>
            </li>
          </ul>

          <div className="nhsuk-tabs__panel pt-0" id="records">
            <br />
            <h2 className="nhsuk-table__caption">Search your local records</h2>
            <h3 className="nhsuk-label">
              Enter at least the first, last name and date of birth to find a
              patient's saved vaccination record in the system.
            </h3>
            <PatientForm
              patientSearchType={patientSearchType}
              setLoading={setLoading}
              setPatients={setPatients}
              setSearchValues={setSearchValues}
              setSubmitButtonClicked={setSubmitButtonClicked}
              setPatient={() => {}}
              setFormikErrors={setFormikErrors}
            />
          </div>
        </div>

        {!loading ? (
          <>
            {patients && patients?.length > 0 ? (
              <>
                <h3>
                  Displaying{" "}
                  {patients.length === 1 ? (
                    <>{patients.length} match</>
                  ) : (
                    <>{patients.length} matches</>
                  )}{" "}
                  for "
                  <b>
                    {searchValues.FirstName}, {searchValues.LastName}
                    {searchValues.Gender ? <>, {searchValues.Gender}</> : ""}
                    {searchValues.Postcode ? (
                      <>, {searchValues.Postcode}</>
                    ) : (
                      ""
                    )}
                    {searchValues.DateOfBirth ? (
                      <>, {IsoToUkDate(searchValues.DateOfBirth)}</>
                    ) : (
                      ""
                    )}
                  </b>
                  "
                </h3>
                <>
                  <table role="table" className="nhsuk-table-responsive">
                    {/*role="rowgroup"*/}
                    <thead className="nhsuk-table__head">
                      <tr role="row">
                        <th role="columnheader" className="" scope="col">
                          Personal details
                        </th>
                        <th role="columnheader" className="" scope="col">
                          NHS number
                        </th>
                        <th role="columnheader" className="" scope="col">
                          Date of birth
                        </th>
                        <th role="columnheader" className="" scope="col">
                          Address
                        </th>
                      </tr>
                    </thead>

                    <tbody className="nhsuk-table__body">
                      {patients.map((patient, index) => (
                        <tr
                          key={index + "trPatient"}
                          role="row"
                          className="nhsuk-table__row"
                        >
                          <td
                            key={index + "tdPatientPersonalDetails"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={index + "tdFragmentPatientPersonalDetails"}
                            >
                              <>
                                <span
                                  key={
                                    index +
                                    "SpanHeadertdFragmentPatientPersonalDetails"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Personal details
                                </span>
                                <NhsActionLink
                                  text={
                                    patient.FirstName + " " + patient.LastName
                                  }
                                  onClick={() => recordVaccination(patient)}
                                ></NhsActionLink>
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={index + "tdPatientNhsNumber"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={index + "tdFragmentPatientNhsNumber"}
                            >
                              <>
                                <span
                                  key={
                                    index +
                                    "SpanHeadertdFragmentPatientNhsNumber"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  NHS number
                                </span>
                                {patient.NhsNumber}
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={index + "tdPatientDateOfBirth"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={index + "tdFragmentDateOfBirth"}
                            >
                              <>
                                <span
                                  key={
                                    index + "SpanHeadertdFragmentDateOfBirth"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Date of brith
                                </span>
                                {IsoToUkDate(patient.DateOfBirth)}
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={index + "tdPatientAddress"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={index + "tdFragmentPatientAddress"}
                            >
                              <>
                                <span
                                  key={
                                    index + "SpanHeadertdFragmentPatientAddress"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Address
                                </span>
                                {patient.Address && patient.Address + ","}{" "}
                                {patient.Postcode}
                              </>
                            </React.Fragment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              </>
            ) : (
              <>
                {submitButtonClicked && patients?.length === 0 ? (
                  <>
                    <h3 className="mb-4">
                      No results found. Check you have spelled the names
                      correctly and try to refine your search.
                    </h3>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </fieldset>
    </>
  );
}
